<template>
  <div>
    <iframe frameborder="no" :src="url" width="100%" :height="heightI"></iframe>
  </div>
</template>

<script>
import Expand from '@/request/expand.js';

export default {
  name: "fileDown",
  data() {
    return {
      heightI: "100pv",
      ask: new Expand(),
      url: ""
    }
  },
  created() {
    this.heightI = window.innerHeight - 60
    if (this.$route.query.id) {
      this.ask.getOthersById(this.$route.query.id).then(res => {
        let data = JSON.parse(res.msg.otherContent)
        this.url = data['url']
        document.title = this.$t('title_') + data['title']
        if (res.msg.otherDesc.length > 0) {
          this.$alert(res.msg.otherDesc, '公告', {
            confirmButtonText: '确定',
          });
        }
      })
    } else {
      this.url = unescape(this.$route.query.url)
    }
  },
  methods: {},
  destroyed() {
    //页面销毁，同时也销毁video.js对象
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>